/*
 * @Author: lizhijie429
 * @Date: 2021-08-20 09:02:14
 * @LastEditors: lizhijie429
 * @LastEditTime: 2021-10-20 14:38:39
 * @Description:
 */
const config = {
  // 微应用配置
  Sub01MicroApp: process.env.VUE_APP_MICRO_SUB01,
  Sub02MicroApp: process.env.VUE_APP_MICRO_SUB02,
  Sub03MicroApp: process.env.VUE_APP_MICRO_SUB03,
  // 后台接口配置
  API_URL: process.env.VUE_APP_API_URL,
  subMenu: [
    {
      title: "vue_blog",
      moduleName: "sub03",
      name: "vue_blog",
      path: "/vue_blog",
      component: "/about",
      children: [],
      type: "menu",
      icon: "about",
      entry: 'https://sixawnstar.com.cn:9030/vue_blog/',
      meta: { isTabs: true, isSide: true, title: "路由跳转", moduleName: "sub03" },
    },
    {
      title: "vue3_blog",
      moduleName: "sub03",
      name: "vue3_blog",
      path: "/vue3_blog",
      component: "/about",
      children: [],
      type: "menu",
      icon: "about",
      entry: 'https://sixawnstar.com.cn:9030/vue3_blog/',
      meta: { isTabs: true, isSide: true, title: "路由跳转", moduleName: "sub03" },
    },
    {
      title: "vue3vite_blog",
      moduleName: "vue3vite_blog",
      name: "vue3vite_blog",
      path: "/vue3vite_blog",
      component: "/about",
      children: [],
      type: "menu",
      icon: "about",
      entry: 'https://sixawnstar.com.cn:9030/vue3vite_blog/',
      meta: { isTabs: true, isSide: true, title: "路由跳转", moduleName: "sub03" },
    },
    {
      title: "react_blog_manage",
      moduleName: "react_blog_manage",
      name: "react_blog_manage",
      path: "/react_blog_manage",
      component: "/about",
      children: [],
      type: "menu",
      icon: "about",
      entry: 'https://sixawnstar.com.cn:9030/react_blog_manage/',
      meta: { isTabs: true, isSide: true, title: "路由跳转", moduleName: "sub03" },
    },
  ],
};

export default config;
