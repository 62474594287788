<!--
 * @Author: lizhijie429
 * @Date: 2021-08-19 11:16:08
 * @LastEditors: lizhijie429
 * @LastEditTime: 2021-08-19 11:18:22
 * @Description: 
-->
<template>
  <div class="logo">
    <span class="site-name">ADMIN LITE</span>
  </div>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  line-height: 64px;
  background: #002140;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
}
</style>
